<template>
  <div class="text-center">
    <etapa-dados-navio
      :descricao="descricao"
      :data_inicio="data_inicio"
      :data_fim="data_fim"
      @set-descricao="setDescricao"
      @set-data-inicio="setDataInicio"
      @set-data-fim="setDataFim"
    />
    <v-row class="pa-5 pt-0">
      <v-spacer />
      <buttons-cadastro @close="close" @save="save" />
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import EtapaDadosNavio from './Etapas/EtapaDadosNavio.vue'
import ButtonsCadastro from '../../ButtonsCadastro/ButtonsCadastro.vue'

export default {
  name: 'FormAdicionarNavio',
  components: {
    EtapaDadosNavio,
    ButtonsCadastro,
  },
  data() {
    return {
      tab: null,
      descricao: '',
      data_inicio: '',
      data_fim: '',
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('patio', ['addNavio']),

    close() {
      this.$emit('addedNavio')
    },

    async save() {
      if (!this.empresaAtual && this.descricao !== '') return
      const data = {
        proprietario: this.empresaAtual.public_id,
        descricao: this.descricao,
        data_inicio: this.data_inicio,
        data_fim: this.data_fim,
      }
      console.log(data)
      await this.addNavio(data)
      this.$emit('addedNavio')
    },

    setDescricao(descricao) {
      this.descricao = descricao
    },
    setDataInicio(data_inicio) {
      this.data_inicio = data_inicio
    },
    setDataFim(data_fim) {
      this.data_fim = data_fim
    },
  },
}
</script>
