<template>
  <v-card class="mb-7">
    <validation-observer ref="observer">
      <v-form>
        <v-row class="d-block">
          <div class="pa-6 py-10 mt-3">
            <v-row>
              <v-col cols="3" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="fornecedor"
                  rules="required"
                >
                  <v-select
                    label="Fornecedor"
                    v-model="novaDistribuicao.fornecedor"
                    :items="fornecedores"
                    item-text="trade_name"
                    item-value="public_id"
                    return-object
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="3" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="cliente"
                  rules="required"
                >
                  <v-select
                    label="Cliente"
                    v-model="novaDistribuicao.cliente"
                    :items="clientes"
                    item-text="trade_name"
                    item-value="public_id"
                    return-object
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="3" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="município"
                  rules="required"
                >
                  <v-text-field
                    label="Município"
                    v-model="novaDistribuicao.cidade"
                    required
                    disabled
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="3" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="estado"
                  rules="required"
                >
                  <v-text-field
                    label="U.F"
                    v-model="novaDistribuicao.estado"
                    required
                    disabled
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mb-0 pb-0" cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="produto"
                  rules="required"
                >
                  <v-select
                    label="Produto"
                    v-model="novaDistribuicao.produto"
                    :items="produtos"
                    item-text="descricao"
                    item-value="id"
                    return-object
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col class="mb-0 pb-0" cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="sub produto"
                  rules="required"
                >
                  <v-select
                    label="Sub Produto"
                    v-model="novaDistribuicao.subProduto"
                    :items="subProdutos"
                    item-text="descricao"
                    item-value="id"
                    return-object
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col class="mb-0 pb-0" cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="embalagem"
                  rules="required"
                >
                  <v-select
                    label="Embalagem"
                    v-model="novaDistribuicao.embalagem"
                    :items="embalagens"
                    item-text="descricao"
                    item-value="id"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col class="mb-0 pb-0" cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="D.I."
                  rules="required"
                >
                  <v-text-field
                    label="D.I."
                    v-model="novaDistribuicao.di"
                    required
                    :error-messages="errors"
                    v-mask="['##/#######-#']"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Número Pedido Imp."
                  rules="required"
                >
                  <v-text-field
                    label="N. Pedido Imp."
                    v-model="novaDistribuicao.nPedidoImp"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="shipment"
                  rules="required"
                >
                  <v-text-field
                    label="Shipment"
                    v-model="novaDistribuicao.shipment"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="terminal de carregamento"
                  rules="required"
                >
                  <v-select
                    label="Terminal de carregamento"
                    v-model="novaDistribuicao.terminal_carregamento"
                    :items="terminais"
                    item-text="trade_name"
                    item-value="public_id"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="acerto de peso"
                  rules="required"
                >
                  <v-select
                    label="Terminal de acerto de peso"
                    v-model="novaDistribuicao.terminal_acerto_peso"
                    :items="terminais"
                    item-text="trade_name"
                    item-value="public_id"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="quantidade (Ton)"
                  rules="required"
                >
                  <v-text-field
                    type="number"
                    label="Quantidade (Ton)"
                    v-model="novaDistribuicao.quantidade"
                    required
                    :step="0.1"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3" xl="2">
                <v-checkbox v-model="hasAtracacao" label="Atracação" />
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="2" v-if="hasAtracacao">
                <v-text-field
                  label="Data Prevista Atracação"
                  v-model="novaDistribuicao.dataPrevistaAtracacao"
                  required
                  type="date"
                />
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="2" v-if="hasAtracacao">
                <v-text-field
                  label="Data Término Operação"
                  v-model="novaDistribuicao.dataTerminoOperacao"
                  required
                  type="date"
                />
              </v-col>
              <v-col
                class="d-none d-md-block d-xl-none"
                cols="12"
                sm="12"
                md="3"
                v-if="hasAtracacao"
              >
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="1" v-if="hasAtracacao">
                <v-checkbox
                  v-model="novaDistribuicao.hasPier"
                  label="Pier/Box"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                xl="2"
                v-if="hasAtracacao && novaDistribuicao.hasPier"
              >
                <v-text-field
                  label="Pier/Box"
                  v-model="novaDistribuicao.pier"
                  required
                  v-mask="['###']"
                />
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-spacer></v-spacer>
            <div class="pa-5 pr-10">
              <buttons-cadastro @close="close" @save="save" />
            </div>
          </v-row>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

import contratosApi from '@/services/tenants/contratos'

import Perfil from '@/utils/perfil'

export default {
  name: 'FormDistribuicao',
  props: {
    novaDistribuicao: Object,
    isEditar: Boolean,
    idEdit: [Boolean, Number],
    navio: Object,
  },
  components: { ButtonsCadastro },
  data() {
    return {
      produtos: [],
      subProdutos: [],
      embalagens: [],
      terminais: [],
      clientes: [],

      hasAtracacao: false,

      distribuicaoObrigatorio: {
        proprietario: true,
        cliente: true,
        fornecedor: true,
        estado: true,
        cidade: true,
        produto: true,
        subProduto: true,
        embalagem: true,
        di: true,
        nPedidoImp: true,
        shipment: true,
        terminal_carregamento: true,
        terminal_acerto_peso: true,
        quantidade: true,
        pier: null,
        hasPier: false,
        dataPrevisaoAtracacao: false,
        dataPrevisaoOperacao: false,
      },
    }
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('distribuicao', ['addDistribuicao', 'editDistribuicao']),
    ...mapActions('patio', ['getEmbalagens', 'getTerminal']),
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('clientes', ['getClientes']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async handleGetFornecedores() {
      if (!this.empresaAtual) return
      await this.getFornecedores(this.empresaAtual)
    },

    close() {
      this.$emit('addedDistribuicao')
    },

    async save() {
      if (!this.novaDistribuicao && !this.navio) return
      const canSend = this.verificaDados(this.novaDistribuicao)

      if (canSend) {
        alert('Preencha todos os campos!')
        return
      }

      const data = {
        ...this.novaDistribuicao,
        proprietario: this.empresaAtual.public_id,
        produto: this.novaDistribuicao.produto.id,
        subProduto: this.novaDistribuicao.subProduto.id,
        navio: this.navio.id,
        cliente: this.novaDistribuicao.cliente.public_id,
        fornecedor: this.novaDistribuicao.fornecedor.public_id,
      }

      if (this.isEditar) {
        try {
          await this.editDistribuicao(data)

          this.successMessage('Distribuição editada com sucesso!')
          this.$emit('addedDistribuicao')
        } catch (err) {
          this.errorMessage(err.response.data)
        }
      } else {
        try {
          await this.addDistribuicao(data)

          this.successMessage('Distribuição adicionado com sucesso!')
          this.$emit('addedDistribuicao')
        } catch (err) {
          this.errorMessage(err.response.data)
        }
      }
    },

    verificaDados(dados) {
      let canSend

      for (let d in this.distribuicaoObrigatorio) {
        if (this.distribuicaoObrigatorio[d]) {
          if (['', null, false].includes(dados[d])) {
            canSend = false
          }
        }
      }

      return canSend
    },

    async verificaIsEditar() {
      if (this.isEditar) {
        const { fornecedor, produto } = this.novaDistribuicao
        if (fornecedor) {
          this.produtos = fornecedor.produtos
          this.embalagens = fornecedor.embalagens
          this.terminais = await contratosApi.getContratos(
            fornecedor.fornecedor_id,
            { perfil: Perfil.Terminal }
          )
          this.subProdutos =
            this.produtos.find((p) => p.id === produto).subprodutos || []
        }
        if (
          this.novaDistribuicao.dataPrevistaAtracacao ||
          this.novaDistribuicao.dataTerminoOperacao
        ) {
          this.hasAtracacao = true
        }
        if (this.novaDistribuicao.pier) {
          this.novaDistribuicao.hasPier = true
          this.hasAtracacao = true
        }
      }
    },
  },
  watch: {
    'novaDistribuicao.fornecedor': async function (fornecedor) {
      this.novaDistribuicao.cidade = fornecedor.city
      this.novaDistribuicao.estado = fornecedor.state

      this.produtos = fornecedor.produtos
      this.embalagens = fornecedor.embalagens
      this.terminais = await contratosApi.getContratos(
        fornecedor.fornecedor_id,
        { perfil: Perfil.Terminal }
      )
      this.clientes = await this.getClientes({
        ...fornecedor,
        public_id: fornecedor.fornecedor_id,
      })
    },

    'novaDistribuicao.produto': function (newVal) {
      this.subProdutos = newVal.subprodutos
    },

    idEdit() {
      if (this.idEdit !== null) {
        this.verificaIsEditar()
      }
    },
  },
  async created() {
    await this.handleGetFornecedores()
    await this.verificaIsEditar()
  },
}
</script>
