<template>
  <div>
    <validation-observer ref="observer">
      <v-form>
        <v-row class="my-8 mx-auto">
          <v-col cols="12" sm="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="data_inicio"
              rules="required"
            >
              <v-text-field
                v-model="input_data_inicio"
                label="Data Inicio"
                required
                :error-messages="errors"
                type="date"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="data_fim"
              rules="required"
            >
              <v-text-field
                v-model="input_data_fim"
                label="Data Fim"
                required
                :error-messages="errors"
                type="date"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="nome do navio"
              rules="required"
            >
              <v-text-field
                label="Nome do navio"
                required
                v-model="input_descricao"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>
<script>
export default {
  name: 'EtapaDadosNavio',
  props: {
    descricao: String,
    data_inicio: String,
    data_fim: String,
  },
  data() {
    return {
      input_descricao: this.descricao,
      input_data_inicio: this.data_inicio,
      input_data_fim: this.data_fim,
    }
  },
  watch: {
    input_descricao(descricao) {
      this.$emit('set-descricao', descricao)
    },
    input_data_inicio(data_inicio) {
      this.$emit('set-data-inicio', data_inicio)
    },
    input_data_fim(data_fim) {
      this.$emit('set-data-fim', data_fim)
    },
  },
}
</script>
