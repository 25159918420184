<template>
  <div class="text-center">
    <v-row class="pa-5 pt-0">
      <v-spacer />
    </v-row>
    <v-row>
      <v-form class="w-100">
        <div class="mt-4 mb-10 mx-16">
          <v-data-table
            :headers="headers"
            :items="distribuicaoDados"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Distribuicões registradas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!editandoDistribuicao"
                  color="primary"
                  dark
                  class="mb-2"
                  @click="$emit('addDistribuicao')"
                  >Nova Distribuição</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editarDistribuicao(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="excluirDistribuicao(item)"
                >mdi-delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <span>Sem distribuições adicionadas</span>
            </template>
          </v-data-table>
        </div>

        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title class="v-card mb-4">
              <span class="headline">Deletar Distribuição</span>
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black darken-1"
                text
                @click="
                  dialogDelete = false
                  distribuicaoDeleteDados = null
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="darken-1"
                style="background: #f00; color: white"
                @click="deletarDistribuicao"
                >Deletar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ListarDistribuicao',
  props: {
    navio: Object,
  },
  data() {
    return {
      editandoDistribuicao: false,
      dialogDelete: false,
      distribuicaoDeleteDados: null,
      headers: [
        {
          text: 'Terminal',
          align: 'start',
          value: 'terminal_carregamento.contratado.trade_name',
        },
        { text: 'Fornecedor', value: 'fornecedor.trade_name' },
        { text: 'Cliente', value: 'cliente.trade_name' },
        { text: 'Munícipio', value: 'cidade' },
        { text: 'D.I.', value: 'di' },
        { text: 'N. Pedido Imp.', value: 'nPedidoImp' },
        { text: 'Shipment', value: 'shipment' },
        { text: 'Produto', value: 'produto.descricao' },
        { text: 'Sub Produto', value: 'subProduto.descricao' },
        { text: 'Embalagem', value: 'embalagem.descricao' },
        { text: 'Quantidade (TON)', value: 'quantidade' },
        { text: 'Restante', value: 'quantidade_restante' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      tab: null,
      descricao: '',
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('distribuicao', ['distribuicao']),

    distribuicaoDados() {
      for (let dist of this.distribuicao) {
        dist.di =
          dist.di.substring(0, 2) +
          '/' +
          dist.di.substring(2, 9) +
          '-' +
          dist.di.slice(-1)
      }
      return this.distribuicao
    },
  },
  methods: {
    ...mapActions('distribuicao', ['getDistribuicao', 'deleteDistribuicao']),

    async getDistribuicaoMethod() {
      if (!this.navio) return

      await this.getDistribuicao(this.navio.id)
    },

    excluirDistribuicao(item) {
      this.dialogDelete = true
      this.distribuicaoDeleteDados = item
    },

    editarDistribuicao(item) {
      this.$emit('editDistribuicao', item)
    },

    async deletarDistribuicao() {
      await this.deleteDistribuicao(this.distribuicaoDeleteDados)
      this.dialogDelete = false
    },

    setDescricao(descricao) {
      this.descricao = descricao
    },
  },
  async created() {
    await this.getDistribuicaoMethod()
  },
}
</script>
