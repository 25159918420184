<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Navios">
          <form-adicionar-navio v-if="showForm" @addedNavio="showForm = false" />
          <listar-distribuicao :navio="navioEdit" v-else-if="showDistribuicao" @editDistribuicao="editDistribuicao" @addDistribuicao="showDistribuicao = false; showFormDistribuicao = true" />
          <form-distribuicao :navio="navioEdit" :isEditar="isEditar" :idEdit="idEdit" :novaDistribuicao="!isEditar ? novaDistribuicao : editarDistribuicaoData" v-else-if="showFormDistribuicao" @addedDistribuicao="addedDistribuicao" />
          <listar-navio v-else @showForm="showForm = true" @listaDistribuicao="listaDistribuicao" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarNavio from '@/components/patio/Navio/ListarNavio'
import FormAdicionarNavio from '@/components/patio/Navio/FormAdicionarNavio'
import ListarDistribuicao from '../../components/patio/Navio/ListarDistribuicao'
import FormDistribuicao from '../../components/patio/Navio/FormDistribuicao'

export default {
  data() {
    return {
      showForm: false,
      showDistribuicao: false,
      showFormDistribuicao: false,
      navioEdit: {},
      idEdit: null,
      isEditar: false,
      page: {
        title: 'Gerenciamento de navios',
      },
      breadcrumbs: [
        {
          text: 'Patio',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Navios',
          disabled: false,
        },
      ],
      novaDistribuicao: {
        proprietario: null,
        cliente: null,
        estado: null,
        cidade: null,
        produto: null,
        subProduto: null,
        embalagem: null,
        di: null,
        nPedidoImp: null,
        shipment: null,
        terminal: null,
        acertoPeso: null,
        quantidade: null,
        pier: null,
        hasPier: false,
        dataPrevistaAtracacao: null,
        dataTerminoOperacao: null,
      },
      distribuicaoDefault: {
        proprietario: null,
        cliente: null,
        estado: null,
        cidade: null,
        produto: null,
        subProduto: null,
        embalagem: null,
        di: null,
        diView: null,
        nPedidoImp: null,
        shipment: null,
        terminal: null,
        acertoPeso: null,
        quantidade: null,
        pier: null,
        hasPier: false,
        dataPrevistaAtracacao: null,
        dataTerminoOperacao: null,
      },
      editarDistribuicaoData: {},
    }
  },
  components: {
    ListarNavio,
    FormAdicionarNavio,
    ListarDistribuicao,
    FormDistribuicao
  },
  methods: {
    listaDistribuicao(item) {
      this.showForm = false
      this.showDistribuicao = true
      this.navioEdit = item
    },

    editDistribuicao (item) {

      this.editarDistribuicaoData = {...item}

      this.editarDistribuicaoData.cliente = this.editarDistribuicaoData.cliente.public_id
      this.editarDistribuicaoData.produto = this.editarDistribuicaoData.produto.id
      this.editarDistribuicaoData.subProduto = this.editarDistribuicaoData.subProduto.id
      this.editarDistribuicaoData.embalagem = this.editarDistribuicaoData.embalagem.id
      this.editarDistribuicaoData.proprietario = this.editarDistribuicaoData.proprietario.id
      this.editarDistribuicaoData.terminal_carregamento = this.editarDistribuicaoData.terminal_carregamento.public_id
      this.editarDistribuicaoData.terminal_acerto_peso = this.editarDistribuicaoData.terminal_acerto_peso.public_id
      this.editarDistribuicaoData.diView = this.editarDistribuicaoData.di

      this.isEditar = true;
      this.idEdit = this.editarDistribuicaoData.id;

      this.showDistribuicao = false;
      this.showFormDistribuicao = true;
    },

    addedDistribuicao() {
      this.showFormDistribuicao = false;
      this.idEdit = null;
      this.isEditar = false;
      this.showDistribuicao = true;


      Object.assign(this.novaDistribuicao, this.distribuicaoDefault);
      this.editarDistribuicaoData = {}
    }

  }
}
</script>

<style></style>
