<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="navios"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Navios registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="handleGetNavios"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="canCreate"
              color="primary"
              dark
              class="mb-2"
              @click="FormAdicionarNavio"
              >Novo navio</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.data_inicio`]="{ item }">
          {{ item.data_inicio | date2br }}
        </template>
        <template v-slot:[`item.data_fim`]="{ item }">
          {{ item.data_fim | date2br }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="canUpdate"
            small
            class="mr-2"
            @click="listaDistribuicao(item)"
            >mdi-package-variant-closed</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>

        <template v-slot:no-data>
          <v-btn v-if="canList" color="primary" @click="handleGetNavios"
            >Reset</v-btn
          >
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// import { ModalCriarEditarPatio } from './ModalCriarEditarPatio'
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  // components: { ModalCriarEditarPatio },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Data Inicio', value: 'data_inicio' },
        { text: 'Data Fim', value: 'data_fim' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
    }
  },
  filters: {
    date2br(data) {
      if (!data) return '-'
      const data_splitted = data.split('-')
      return `${data_splitted[2]}/${data_splitted[1]}/${data_splitted[0]}`
    },
  },
  computed: {
    ...mapState('patio', ['navios']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Navios.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Nova' : 'Editar'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.handleGetNavios()
    }
  },

  methods: {
    ...mapActions('patio', ['getNavios', 'addNavio', 'editNavio']),

    async handleGetNavios() {
      await this.getNavios({ tenant: this.empresaAtual.public_id })
    },

    editItem(item) {
      this.editedIndex = this.navios.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    deleteItem(item) {
      console.log(item)
      // const index = this.desserts.indexOf(item)
      // confirm('Are you sure you want to delete this item?') &&
      //   this.desserts.splice(index, 1)
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editNavio(this.editedItem)
      } else {
        this.editedItem.proprietario = this.empresaAtual.id
        this.addNavio(this.editedItem)
      }
      this.close()
    },

    listaDistribuicao(item) {
      this.$emit('listaDistribuicao', item)
    },

    FormAdicionarNavio() {
      this.$emit('showForm')
    },
  },
}
</script>
